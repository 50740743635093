<script>
export default {
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    isMounted: false
  }),
  computed: {
    productName() {
      return this.row.variantName || this.row.name || '';
    },
    articleNumberForDisplay() {
      return this.row.articleNumberForDisplay || '';
    },
    variantType() {
      let variant = '';
      if (this.row.color) {
        variant += this.row.size
          ? `${this.row.color} / ${this.row.size}`
          : this.row.color;
      } else {
        variant += this.row.size || '';
      }
      return variant;
    },
    quantity() {
      return this.row.quantity || 1;
    },
    unitOfMeasurement() {
      return this.row.unitOfMeasurement || '';
    },
    unitPrice() {
      return this.row.unitPrice;
    },
    totalRow() {
      if (this.row.price) return this.row.price * this.quantity;
      return this.unitPrice * this.quantity;
    },
    imageId() {
      return this.row.giftCardImageId || this.row.imageId || null;
    },
    imageUrl() {
      return this.row.image || null;
    }
  },
  methods: {
    getProductImageUrl() {
      const imageHeight =
        this.$refs?.imageContainer?.getBoundingClientRect()?.height ?? 300;
      const imageId = this.imageId;
      if (imageId)
        return (
          this.$toLitiumMediaUrl(imageId, { maxHeight: imageHeight }) ||
          this.imageUrl ||
          ''
        );
      const imageUrl = this.imageUrl;
      if (imageUrl) return imageUrl;
      return '';
    }
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<template>
  <div class="receipt-order-info-row">
    <div class="receipt-order-info-row__info">
      <div class="receipt-order-info-row__thumbnail-container">
        <div class="receipt-order-info-row__variant-image-overlay"></div>
        <img
          v-if="isMounted && (imageId || imageUrl)"
          :src="getProductImageUrl()"
          :alt="productName"
          loading="lazy"
          class="receipt-order-info-row__variant-image"
        />
      </div>

      <div class="receipt-order-info-row__details">
        <div>
          <div class="receipt-order-info-row__row-info">
            <div
              class="receipt-order-info-row__row-info-left receipt-order-info-row__row-info-left--bold"
            >
              {{ productName }}
            </div>
            <div class="receipt-order-info-row__row-info-right">
              {{ variantType }}
            </div>
          </div>
          <div class="receipt-order-info-row__row-info">
            <div class="receipt-order-info-row__row-info-left">
              {{ articleNumberForDisplay }}
            </div>
          </div>
          <div class="receipt-order-info-row__row-info">
            <div
              class="receipt-order-info-row__row-info-left receipt-order-info-row__row-info-left--bold"
            >
              {{ quantity }} {{ unitOfMeasurement }}
              <span class="receipt-order-info-row__row-info-left--regular"
                >x {{ $formatPrice(unitPrice) }}</span
              >
            </div>
            <div
              class="receipt-order-info-row__row-info-right receipt-order-info-row__row-info-right--bold"
            >
              {{ $formatPrice(totalRow) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.receipt-order-info-row {
  border-radius: 3px;
  background-color: #fff;
}

.receipt-order-info-row__availability {
  font-size: 13px;
  color: #6d6d8e;
  line-height: 18px;
}

.receipt-order-info-row__info {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 0 0 0.5rem;
  position: relative;
}

.receipt-order-info-row__thumbnail-container {
  position: relative;
  width: 40px;
  height: 49px;
  user-select: none;
}

.receipt-order-info-row__thumbnail {
  padding-top: 130%;
}

.receipt-order-info-row__image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  background-color: #efefef;
  mix-blend-mode: darken;
}

.receipt-order-info-row__variant-image {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  margin: auto;
}

.receipt-order-info-row__variant-image-overlay {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  background-color: #efefef;
  mix-blend-mode: darken;
}

.receipt-order-info-row__details {
  width: 100%;
  padding: 0 0 0 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.receipt-order-info-row__row-info {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  letter-spacing: 0.18px;
  font-family: 'Mulish';
  color: #000000;
  line-height: 14px;
  cursor: pointer;
  padding-bottom: 0.25rem;
}

.receipt-order-info-row__row-info-left,
.receipt-order-info-row__row-info-right {
  color: #797b80;
}

.receipt-order-info-row__row-info-left--regular {
  color: #797b80;
  font-weight: 400;
}

.receipt-order-info-row__row-info-right--bold,
.receipt-order-info-row__row-info-left--bold {
  color: #000000;
  font-weight: 700;
}
</style>
